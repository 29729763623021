<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 c g" v-if="!selectedPlace">
      <div class="card card-body">
        <div class="form-group">
          <h4 for="">اختر المدينة او المنطقة</h4>
          <select
            class="form-control form-control-lg"
            style="cursor: pointer"
            v-model="selectedPlace"
            name=""
            id=""
          >
            <option :value="null">-- اختر --</option>
            <option v-for="place in places" :key="place._id" :value="place">
              {{ place.title }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="selectedPlace">
      <div class="card">
        <select name="" style="border: none" id="" v-model="selectedPlace">
          <option v-for="place in places" :key="place._id" :value="place">
            تغيير المدينة إلى --> {{ place.title }}
          </option>
        </select>
        <div class="card-header bg-light" style="padding: 0px">
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-lg-4 g text-center">
                <div>
                  <img
                    :src="require('@/assets/images/delivery.png')"
                    style="width: 90px"
                    alt=""
                  />
                  <h2 class="text-success">مُتاح التوصيل</h2>
                  <p>مُتاح توصيل الأجهزة إلى {{ selectedPlace.title }}.</p>
                </div>
              </div>
              <div class="col-12 col-lg-4 g text-center">
                <div v-if="selectedPlace.install">
                  <img
                    :src="require('@/assets/images/install.png')"
                    style="width: 90px; padding: 10px"
                    alt=""
                  />
                  <h2 class="text-success">مُتاح التركيب</h2>
                  <p>مُتاح تركيب الاجهزة وتشغيلها من قبل الفني.</p>
                </div>
                <div v-if="!selectedPlace.install">
                  <img
                    :src="require('@/assets/images/warning.png')"
                    style="width: 90px; padding: 10px"
                    alt=""
                  />
                  <h2 class="text-danger">غير مُتاح التركيب</h2>
                  <p>
                    غير متاحد تركيب الاجهزة من قبل الفني في
                    {{ selectedPlace.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="card card-body">
            <div class="row">
              <div class="col-12 col-lg-2 g text-center">
                <img
                  :src="device.image"
                  style="width: 100%; max-width: 200px"
                />
              </div>
              <div class="col-12 col-lg-6 g">
                <br />
                <h4>
                  {{ device.title }}
                </h4>
                <h5 v-if="device.available" class="text-success">
                  <i class="fa fa-check"></i> الجهاز متوفر
                </h5>
                <h5 v-if="!device.available" class="text-danger">
                  <i class="fa fa-times"></i> الجهاز غير متوفر
                </h5>
                <p v-html="device.details.replaceAll('\n', '<br>')"></p>
              </div>
              <div class="col-12 col-lg-4 g">
                <label for="demo-sb">الكمية المطلوبة</label>
                <b-form-spinbutton
                  id="demo-sb"
                  v-model="count"
                  min="1"
                  max="100"
                />
                <div v-if="selectedPlace.install">
                  <br />
                  <b-form-checkbox
                    class="custom-control-primary"
                    name="check-button"
                    v-model="install"
                    >التركيب بواسطة الفني ({{ selectedPlace.install_price }}
                    ريال لكل جهاز)
                  </b-form-checkbox>
                </div>
                <br />
                <div
                  class="col-12 table-responsive"
                  style="min-height: 10px !important"
                >
                  <table class="table table-bordered custom-table">
                    <tbody>
                      <tr>
                        <td>
                          سعر
                          <span v-if="count > 1">
                            الاجهزة ({{ count }} جهاز) </span
                          ><span v-if="count <= 1"> الجهاز </span>
                          <br />
                          (السعر يشمل الضريبة والشحن والبرمجة)
                        </td>
                        <td>
                          <strong>{{ count * device.price }}</strong>
                        </td>
                      </tr>
                      <tr v-if="install && selectedPlace.install">
                        <td>
                          تكلفة التركيب
                          <span> ({{ count }} جهاز) </span>
                        </td>
                        <td>
                          <strong>{{
                            count * selectedPlace.install_price
                          }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          تكلفة التوصيل
                          <span> ({{ count }} جهاز) </span>
                        </td>
                        <td>
                          <strong>{{
                            count * selectedPlace.delivery_price
                          }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          الضريبة 15%
                          <span> ({{ count }} جهاز) </span>
                        </td>
                        <td>
                          <strong>{{
                            count * device.price * 0.15
                          }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <th>الاجمالي</th>
                        <th style="font-family: arial !important">
                          {{ total() }} ريال
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button
                  class="btn btn-block btn-relief-success"
                  v-if="device.available"
                  @click="buy()"
                  v-b-modal.modal-1
                >
                  اطلب الآن
                  <i class="fa fa-arrow-left"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="اكمال طلب"
      ok-only
      size="lg"
      hide-footer
      ok-title="Accept"
    >
      <div>
        <div class="col-12 g">
          <div class="card card-body">
            <p>تحويل المبلغ المطلوب لإكمال الطلب</p>
            <h4>
              رقم الحساب بمصرف الراجحي
              <br>
              372608010592749
              <br>
              رقم الايبان للتحويل من خارج المصرف
              <br>
              SA7080000372608010592749
              <br>
              باسم مؤسسة الصدى لتقنية المعلومات
            </h4>
            <h5>
              اجمالي المبلغ المطلوب: {{ selectedPlace ? total() : 0 }} ريال
            </h5>
          </div>
        </div>
        <div class="form-group border g">
          <h5 for="">صورة او ملف ايصال التحويل</h5>
          <div id="progress-wrp">
            <div class="progress-bar"></div>
            <div class="status">0%</div>
          </div>
          <input type="file" title="اضغط للرفع" id="ingredient_file" />
        </div>
        <div class="form-group">
          <h5 for="">رقم الجوال</h5>
          <input type="text" class="form-control" v-model="phone" />
        </div>
        <div class="form-group">
          <h5 for="">العنوان (المراد الشحن له)</h5>
          <textarea
            class="form-control"
            v-model="address"
            rows="3"
            placeholder="اكتب هنا..."
          ></textarea>
        </div>
        <div class="form-group">
          <h5 for="">هل تريد اضافة اي ملاحظات؟</h5>
          <textarea
            class="form-control"
            v-model="notes"
            rows="3"
            placeholder="اكتب هنا..."
          ></textarea>
        </div>
        <div class="col-12 text-center g">
          <button class="btn btn-success" @click="buyDone()">
            <i class="fa fa-check"></i>
            {{ loading ? "جاري الارسال..." : "إرسال الطالب" }}
          </button>
        </div>
        <!-- <div class="alert alert-danger text-center">
          <h4>
            <strong>شراء الاجهزة غير متاح حالياً.</strong>
            <br>
            يمنكك شراء احد الاجهزة التالية من اي متجر وربطها بانظمتنا:
            <br>
            
<ul>
  <li>
    Zkteco ua400
  </li>
  <li>
    
Zkteco ua760
  </li>
  <li>
    
Zkteco mb 2000
  </li>
  <li>
    
Zkteco uface 800
  </li>
  <li>
    
Zkteco SilkFP-101TA
  </li>
  <li>
Zkteco mb1000</li>
</ul>





          </h4>
        </div> -->
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BFormSpinbutton,
  BFormCheckbox,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
  },
  components: {
    BOverlay,
    BFormSpinbutton,
    BFormCheckbox,
  },
  data() {
    return {
      places: [],
      device: {},
      count: 1,
      address: null,
      notes: null,
      file: null,
      install: true,
      user: JSON.parse(localStorage.getItem("user")),
      phone: null,
      selectedPlace: null,
      loading: true,
    };
  },
  methods: {
    total() {
      var total = 0;
      total = total + this.count * this.device.price;
      total = total + total * 0.15;
      if (this.install && this.selectedPlace.install) {
        total = total + this.count * this.selectedPlace.install_price;
        total = total + this.count * this.selectedPlace.delivery_price;
      }
      return total;
    },
    buy() {
      var g = this;
      var Upload = function (file) {
        this.file = file;
      };

      Upload.prototype.getType = function () {
        return this.file.type;
      };
      Upload.prototype.getSize = function () {
        return this.file.size;
      };
      Upload.prototype.getName = function () {
        return this.file.name;
      };
      Upload.prototype.doUpload = function () {
        var that = this;
        var formData = new FormData();

        // add assoc key values, this will be posts values
        formData.append("file", this.file, this.getName());
        formData.append("jwt", g.user.token);
        $.ajax({
          type: "POST",
          url: api + "/client/devices/upload",
          xhr: function () {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener(
                "progress",
                that.progressHandling,
                false
              );
            }
            return myXhr;
          },
          success: function (data) {
            $("#progress-wrp").fadeOut("slow");
            if (data.status == 100) {
              g.file = data.response;
            } else {
              alert("صيغة الملف غير مدعومة");
            }
          },
          error: function (error) {
            // handle error
            alert("حدث خطأ");
          },
          async: true,
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 60000,
        });
      };

      Upload.prototype.progressHandling = function (event) {
        var percent = 0;
        var position = event.loaded || event.position;
        var total = event.total;
        var progress_bar_id = "#progress-wrp";
        if (event.lengthComputable) {
          percent = Math.ceil((position / total) * 100);
        }
        // update progressbars classes so it fits your code
        $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
        $(progress_bar_id + " .status").text(percent + "%");
      };
      setTimeout(() => {
        $("#ingredient_file").on("change", function (e) {
          $("#progress-wrp").fadeIn("slow");
          var file = $(this)[0].files[0];
          var upload = new Upload(file);

          // maby check size or type here with upload.getSize() and upload.getType()

          // execute upload
          upload.doUpload();
        });
      }, 1000);
    },
    buyDone() {
      var g = this;
      if (!this.phone) {
        alert("برجاء كتابة رقم الجوال");
      } else {
        if (!this.address) {
          alert("برجاء كتابة العنوان");
        } else {
          if (!this.count) {
            alert("برجاء اختيار العدد الخاص بالاجهزة");
          } else {
            if (!this.file) {
              alert("برجاء رفع ايصال الدفع");
            } else {
              g.loading = true;
              $.post(api + "/client/devices/add-order", {
                token: this.user.token,
                phone: this.phone,
                address: this.address,
                notes: this.notes,
                file: this.file,
                device_id: this.device._id,
                count: this.count,
                place_id: this.selectedPlace._id,
                install: this.install,
              })
                .then(function () {
                  g.loading = false;
                  g.$router.push("/orders");
                })
                .catch(function () {
                  g.loading = false;
                  alert(
                    "حدث مشكلة في ارسال الطلب، برجاء اعادة المحاولة او التواصل مع الدعم الفني."
                  );
                });
            }
          }
        }
      }
    },
  },
  created() {
    var g = this;
    g.phone = this.user.phone;
    $.post(api + "/client/devices/device", {
      id: window.location.href.split("/buy/")[1],
    })
      .then(function (r) {
        g.device = r.response;
        $.post(api + "/client/devices/places")
          .then(function (r) {
            g.places = r.response;
            g.loading = false;
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      })
      .catch(function () {
        alert("حدث خطأ");
      });
  },
};
</script>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>